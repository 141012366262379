<template>
    <div class="ssoLogin">
        <p>{{msg}}</p>
    </div>
</template>

<script>
    /*import  from '';*/
    export default {
        name: '',
        components: {},
        data () {
            return {
                msg: '登录中...'
            }
        },
        created(){
            console.log(this.$route.query)//cypher
            let query = this.$route.query;
            if(query.cypher){
                this.axios('GET',`${this.$phpBaseURL}v1/ssoLogin`,{
                    cypher:query.cypher
                }).then(res=>{
//                console.log(res.data)
                    if(res.code == 1){
                        let str = JSON.stringify(res.data);
                        localStorage.setItem('user_info',str);

                        //根据权限跳转
                        this.$router.push({path: res.data.menu_to, query:{form:'login'}});
//                        this.$router.push({path: '/customer',query:{form:'login'}});
                    } else if(res.code == 2){
                        //token过期处理
                        this.common.tokenExpired(res.msg);
                    }else{
                        this.$message({ message: res.msg, type: 'warning' });
                    }
                })
            }else {
                this.msg='登录参数错误'
            }
        },
        methods: {}
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .ssoLogin{
        width: 300px;
        height: 50px;
        margin: 0 auto; /*水平居中*/
        position: relative;
        text-align: center;
        top: 40%; /*偏移*/
        font-size: 35px;
        color: rgba(1,172,228,1);
    }
</style>